.about-me-section {
    width: 100%; /* Full width */
    margin: 0;
    padding: 0;
    text-align: center;
    background: linear-gradient(135deg, #ffbb00, #444444);
    box-sizing: border-box; /* Include padding in width */
}

.slider-container {
    margin-bottom: 20px;
}

.cv-container {
    width: 50%;
    margin: 0 auto;
    padding: 20px;
    background: transparent;
}

.slider-item {
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    height: 100px;
    width: 90%; /* Adjust width to make it less wide */
    padding: 0.7rem 2%; /* Ensure proper spacing inside */
    background: #000000;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: regular;
    text-align: center;
    transition: transform 0.3s, background-color 0.3s;
    margin: 0 auto; /* Center the slider item horizontally within its container */
    box-sizing: border-box; /* Include padding and borders in the total width */
}

.slider-item p {
    display: flex;
    justify-content: center; /* Centers the text horizontally inside the <p> */
    align-items: center; /* Centers the text vertically inside the <p> */
    height: 100%; /* Make the <p> element take the full height of the .slider-item */
    width: 100%; /* Set the <p> width to 100% to ensure it fills the parent container */
    margin: 0; /* Remove any default margins */
    padding: 0; /* Remove any extra padding */
    text-align: center; /* Center the text inside the <p> */
    box-sizing: border-box; /* Include padding in the element's width */
}


.slider-item:hover {
    transform: scale(1.05);
    background-color: #007bff;
}

.slick-dots li button:before {
    color: #f9f9f9;
    font-size: 12px;
}

.slick-dots li.slick-active button:before {
    color: #ffbb00;
    opacity: 1;
}

.slick-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #000000, #000000); /* Gradient background */
    color: #f9f9f9;
    padding: 0.1rem 0; /* Adjust padding to avoid overflow */
    width: 100%; /* Ensure it takes the full width of the container */
    box-sizing: border-box;
}

.slick-prev,
.slick-next {
    width: 40px;
    height: 40px;
    z-index: 10;
    background-color: #444444;
    color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s, background-color 0.3s;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.slick-prev:hover,
.slick-next:hover {
    background-color: #ffbb00;
    transform: scale(1.1);
}

.slick-prev:before,
.slick-next:before {
    font-family: "slick";
    font-size: 20px;
    color: #ffffff;
}

.slick-prev {
    left: 10px;
}

.slick-next {
    right: 10px;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
    .cv-container {
        width: 70%;
        padding: 15px;
    }

    .slider-item {
        height: 80px;
        padding: 10px;
        font-size: 1.2rem;
    }

    .slider-item p {
        font-size: 1rem; /* Adjust font size for smaller screens */
    }

    .slick-prev,
    .slick-next {
        display: none !important; /* Hide arrows on phone screens */

    }
}

@media (max-width: 480px) {
    .cv-container {
        width: 90%;
        padding: 10px;
    }

    .slider-item {
        height: 90px;
        padding: 5px;
        font-size: 1rem;
    }

    .slider-item p {
        font-size: 0.875rem; /* Further reduce font size on very small screens */
    }


    .slick-prev,
    .slick-next {
        display: none !important; /* Hide arrows on phone screens */
    }
}