/* App.css */
.nav-link {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  transition: color 0.3s, background-color 0.3s;
}

.nav-link:hover {
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
}

.nav-link.active {
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
}

/* Button.css */
.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff; /* Primary color */
  border: none;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
  transform: translateY(-2px); /* Lift effect */
}

.btn:active {
  background-color: #004494; /* Even darker shade when clicked */
  transform: translateY(0); /* Return to original position */
}

* {
  transition: all 0.3s ease-in-out;
}


/* Media queries for smaller screens */
@media (max-width: 600px) {
  .nav-link {
    padding: 0.25rem 0.5rem;
    margin: 0 0.25rem;
    font-size: 0.875rem;
  }
}

/* Media queries for smaller screens */
@media (max-width: 600px) {
  .btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}