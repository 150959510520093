.expirienceSection {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
    justify-content: center;
    padding: 2rem;
    background-color: #000000; /* Match the header background */
    color: #ffffff; /* Text color */
    text-align: center; /* Center text alignment */
}

.expirienceList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap; /* Allow wrapping for responsiveness */
    justify-content: center; /* Center the expirience list */
    gap: 1rem; /* Spacing between expirience */
}

.expirienceItem {
    text-decoration: none;
    font-weight: bold;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #ffffff; /* Add a border */
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s, transform 0.2s;
    text-align: left; /* Align content inside the button to the left */
}

.expirienceItem:hover {
    color: #ffffff;
    background-color: #007bff; /* Highlight on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
    border: 1px solid #007bff;
}

.expirienceName {
    font-weight: bold;
    font-size: 1rem; /* Revert to original size */
    color: #ffffff;
}

.expirienceYears {
    font-size: 0.7rem; /* Match the smaller font size from original design */
    color: #cccccc; /* Lighter color to differentiate */
    margin-top: 0.25rem; /* Add spacing below the name */
    display: block; /* Ensure years appears on a new line */
}

.expirienceRole {
    font-size: 1rem; /* Match the original description text size */
    color: #007bff; /* Slightly lighter color */
    margin-top: 0.5rem; /* Add spacing below the name */
}


.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: fit-content; /* Adjust width to fit the content */
    max-width: 90%; /* Limit max width to 90% of the viewport */
    min-width: fit-content; /* Ensure minimum width fits the content */
    height: auto; /* Allow content to determine height */
    max-height: 90%; /* Ensure it fits within the viewport height */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow-y: auto; /* Allow vertical scrolling if content overflows */
    overflow-x: hidden; /* Disable horizontal scrolling */
}



.overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.closeButton {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.closeButton:hover {
    background-color: #0056b3;
}

@media (max-width: 1024px) {
    .modal {
        width: 95%; /* Slightly smaller max-width */
        max-height: 85%; /* Slightly smaller max-height */
        padding: 15px; /* Adjust padding */
        white-space: normal; /* Allow text wrapping on smaller screens */

    }
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
    .expirienceSection {
        padding: 1.5rem;
    }

    .expirienceItem {
        padding: 0.5rem;
        margin: 0.25rem;
        font-size: 0.875rem;
    }

    .modal {
        width: 95%;
        max-height: 90%; /* Ensure it fits within the viewport */
        padding: 15px;
        white-space: normal; /* Allow text wrapping on smaller screens */

    }

    .closeButton {
        padding: 8px 16px;
        font-size: 0.875rem;
    }
}

@media (max-width: 480px) {
    .expirienceSection {
        padding: 1rem;
    }
    
    .expirienceList {
        flex-direction: column; /* Display items vertically */
        width: 50%; /* Ensure it takes the full width of the container */
        box-sizing: border-box; /* Include padding and border in the element's total width and height */
    }

    .expirienceItem {
        padding: 0.25rem;
        margin: 0.25rem;
        font-size: 0.75rem;
        text-align: center;
        box-sizing: border-box; /* Include padding and border in the element's total width and height */
    }

    .modal {
        width: 95%;
        max-height: 85%; /* Slightly reduce for very small screens */
        padding: 5px;
        overflow-y: auto; /* Ensure scrollability for overflowing content */
    }


    .closeButton {
        padding: 6px 12px;
        font-size: 0.75rem;
    }
    .ul{
        list-style-type: disc; /* Use a consistent bullet style */
        margin-left: 20px; /* Indent the list for readability */
        padding: 0;
        text-align: left; /* Ensure list items are aligned to the left */
    }
}