/* Base styles for the header and navigation links */
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2%;
    background-color: #000000;
    color: #fff;
    font-size: 1rem;
}

nav a {
    margin: 0 0.5rem;
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
}

/* Base styles for the about modal */
.about-modal {
    position: fixed; /* Use fixed to prevent the modal from moving with scroll */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90%; /* Set width to 90% of the viewport */
    height: auto;
    max-height: 90%; /* Limit max height to 90% of the viewport */
    overflow-y: auto; /* Allow vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
    text-align: center; /* Center align the text inside the modal */
    white-space: normal; /* Allow text to wrap */
}

.about-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(8px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.about-modal h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
}

.about-modal p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #555;
    text-align: center;
    word-wrap: break-word;
}

.about-modal button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.about-modal button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    header {
        flex-direction: column;
        text-align: center;
        padding: 0.5rem 1%;
    }

    nav a {
        display: block;
        margin: 0.5rem 0;
    }
}

/* Adjustments for very small screens */
@media (max-width: 480px) {
    header {
        font-size: 0.9rem;
    }

    nav a {
        font-size: 0.9rem;
    }

    .about-modal {
        width: 90%; /* Maintain 90% width for consistency */
        padding: 10px; /* Adjust padding */
        overflow-y: auto; /* Ensure scrollability for overflowing content */
    }

    .about-modal h2,
    .about-modal p,
    .about-modal button {
        text-align: center; /* Center align all text */
    }

    .about-modal p {
        white-space: normal; /* Ensure text wraps naturally */
    }
}

/* Additional adjustments for extra small screens */
@media (max-width: 320px) {
    header {
        font-size: 0.8rem;
        padding: 0.25rem 0.5%;
    }

    nav a {
        font-size: 0.8rem;
        margin: 0.25rem 0;
    }

    .about-modal h2 {
        font-size: 1.5rem;
    }

    .about-modal p {
        font-size: 0.875rem;
    }

    .about-modal button {
        padding: 8px 16px;
        font-size: 0.875rem;
    }
}
