/* Modal-specific styles */
.modal-form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Spacing between form fields */
    width: 100%;
    max-width: 100%; /* Ensure the form doesn't exceed the viewport width */
    padding: 0 15px; /* Add padding to prevent touching the screen edges */
    box-sizing: border-box; /* Include padding in the element's total width */
    align-items: center; /* Center align for form elements */
}

.modal-form h2 {
    text-align: center; /* Center-align the heading */
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
    width: 100%; /* Ensure it takes full width */
}

/* Input and textarea styling within the modal */
.modal-form input[type="text"],
.modal-form input[type="email"],
.modal-form input[type="tel"],
.modal-form textarea {
    width: 100%;
    max-width: 500px; /* Consistent max-width with distance textbox */
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #333; /* Dark text color */
    background-color: #ffffff; /* White background */
    border: 1px solid #000000; /* Black border for contrast */
    border-radius: 5px; /* Rounded corners */
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
}

/* Hover and focus effects for inputs and textarea */
.modal-form input[type="text"]:focus,
.modal-form input[type="email"]:focus,
.modal-form input[type="tel"]:focus,
.modal-form textarea:focus {
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 91, 187, 0.5); /* Shadow effect on focus */
}

/* Textarea specific styling */
.modal-form textarea {
    resize: vertical;
    min-height: 100px;
}

/* Button styling within the modal */
.modal-form button[type="submit"] {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #007bff; /* Blue background */
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.modal-form button[type="submit"]:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-2px);
}

.modal-form button[type="submit"]:focus {
    background-color: #004494; /* Even darker blue on focus */
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 68, 148, 0.5);
}

.error-message {
    color: red;
    font-size: 1rem;
    margin: 10px 0;
    text-align: center;
    visibility: visible; /* Ensure it's not hidden */
    display: block; /* Ensure it occupies space */
}

.success-message {
    color: #28a745;
    font-size: 1rem;
    margin: 10px 0;
    text-align: center;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
    .modal-form h2 {
        font-size: 1.5rem;
    }

    .modal-form input[type="text"],
    .modal-form input[type="email"],
    .modal-form input[type="tel"],
    .modal-form textarea {
        max-width: 90%;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
    }

    .modal-form button[type="submit"] {
        padding: 8px 16px;
        font-size: 0.875rem;
    }
}

@media (max-width: 480px) {
    .modal-form h2 {
        font-size: 1rem;
    }

    .modal-form input[type="text"],
    .modal-form input[type="email"],
    .modal-form input[type="tel"],
    .modal-form textarea {
        max-width: 90%;
        padding: 0.5rem;
        font-size: 0.75rem;
    }

    .modal-form button[type="submit"] {
        padding: 6px 12px;
        font-size: 0.75rem;
    }
}

.ReactModal__Content {
    max-width: 90%; /* Use a percentage that adapts to the screen size */
    margin: 0 auto; /* Center horizontally */
    padding: 15px; /* Adjust padding */
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    overflow: auto; /* Allow content to scroll if needed */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    box-sizing: border-box; /* Ensure padding is included in the width */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items horizontally */
}

@media (max-width: 768px) {
    .ReactModal__Content {
        max-width: 75%;
        max-height: 50%;
        padding: 15px; /* Ensure padding from the sides */
    }
}

@media (max-width: 480px) {
    .ReactModal__Content {
        max-width: 75%; /* Allow the modal to fill most of the screen width */
        max-height: 80%;
        padding: 15px; /* Add some side padding for mobile screens */
    }
}
