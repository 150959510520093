/* NavLink.module.css */
.navLink {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    transition: color 0.3s, background-color 0.3s;
}

.navLink:hover {
    color: #fff;
    background-color: #007bff;
    border-radius: 5px;
}

.active {
    color: #fff;
    background-color: #007bff;
    border-radius: 5px;
}
/* Media queries for smaller screens */
@media (max-width: 768px) {
    .navLink {
        padding: 0.5rem 0.75rem;
        margin: 0 0.25rem;
        font-size: 0.875rem;
    }
}

@media (max-width: 480px) {
    .navLink {
        padding: 0.25rem 0.5rem;
        margin: 0 0.25rem;
        font-size: 0.75rem;
    }
}