/*
.profile-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 420px; 
    height: 620px; 
    border-radius: 10%;
    background: linear-gradient(45deg, #ffbb00, #000000); 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); 
    padding: 10px; 
    margin: 20px auto; 
    transition: transform 0.3s, box-shadow 0.3s; 
}

.profile-image-wrapper:hover {
    transform: scale(1.05); 
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5); 
}

.profile-image {
    border-radius: 5%;
    width: 400px;
    height: 600px;
    object-fit: cover; 
}
*/
.profile-image {
    display: block;
    margin: 20px auto; /* Center the image */
    border-radius: 10%; /* Slightly rounded corners */
    width: 400px;
    height: 600px;
    object-fit: cover;
    background: linear-gradient(45deg, #ffbb00, #000000); /* Optional background gradient */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
    transition: transform 0.3s, box-shadow 0.3s;
}

.profile-image:hover {
    transform: scale(1.06); /* Slightly enlarge on hover */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5);
}

/* ProfilePic.css */
.profile-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 410px;
    height: 610px;
    border-radius: 0%;
    background: transparent;
    padding: 10px;
    margin: 20px auto; /* Center horizontally */
    transition: transform 0.3s, box-shadow 0.3s;
}



@media (max-width: 768px) {
    .profile-image-wrapper {
        width: 300px;
        height: 450px;
    }

    .profile-image {
        width: 280px;
        height: 430px;
    }

}
@media (max-width: 480px) {
    .profile-image-wrapper {
        width: 240px;
        height: 360px;
    }

    .profile-image {
        width: 240px;
        height: 360px;
    }
}