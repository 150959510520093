/* Header.css */
footer {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: #000000;
    color: #fff;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    footer {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center-align items */
        text-align: center; /* Center-align text */
    }
}

/* Adjustments for very small screens */
@media (max-width: 480px) {
    footer {
        padding: 0.5rem; /* Reduce padding */
        font-size: 0.875rem; /* Slightly smaller font size */
    }
}