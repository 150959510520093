.cv-section {
    width: 100%; /* Full width */
    background: #ffffff;
    padding: 20px;
    text-align: center;
    box-sizing: border-box; /* Include padding in width */
}


.cv-section .cv-header {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #ffffff;
    text-align: center;
}

.cv-section .cv-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.cv-section .cv-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; /* Space between icon and text */
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #000000;
    border: 1px solid #ffffff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    text-align: center;
}
.cv-icon {
    font-size: 1.2rem; /* Adjust icon size */
    vertical-align: middle; /* Align icon with text */
}
.cv-section .cv-button:hover {
    background-color: #007bff;
    transform: scale(1.05);
}

.cv-section .cv-button:focus {
    background-color: #0056b3;
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 68, 148, 0.5);
}

.cv-section .cv-button:active {
    background-color: #004494;
    transform: scale(0.95);
}

.cv-section .pdf-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    margin: auto;
    max-width: 70%;
    max-height: 70%;
    width: 60%;
    height: calc(60vw * 1.05);
    overflow: hidden;
}

.cv-section .pdf-iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
}

.cv-section .close-pdf {
    margin-bottom: 10px;
    background-color: #ff4a4a;
    color: #ffffff;
    padding: 5px 10px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cv-section .close-pdf:hover {
    background-color: #d43a3a;
}
/* Media queries for smaller screens */
@media (max-width: 768px) {
    .cv-section .cv-header {
        font-size: 1.2rem;
    }

    .cv-section .cv-buttons {
        flex-direction: column;
        gap: 5px;
    }

    .cv-section .cv-button {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
    }

    .cv-section .pdf-viewer {
        max-width: 90%;
        max-height: 90%;
        width: 80%;
        height: calc(80vw * 1.05);
    }
}

@media (max-width: 480px) {
    .cv-section .cv-header {
        font-size: 1rem;
    }

    .cv-section .cv-button {
        padding: 0.25rem 0.5rem;
        font-size: 0.75rem;
        width: 60%;
        align-self: center;
    }

    .cv-section .pdf-viewer {
        max-width: 85%;
        max-height: 100%;
        width: 100%;
        height: calc(100vw * 1.05);
    }

    .cv-section .close-pdf {
        padding: 3px 6px;
        font-size: 0.875rem;
    }
}